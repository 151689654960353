export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Blanc is a data QA and observability platform. It will help in bringing more quality control, predictability and automation into our data assets and pipelines."},{"hid":"og:title","property":"og:title","content":"McKesson Compile "},{"hid":"og:site_name","property":"og:site_name","content":"blanc.compile.com"},{"hid":"og:description","property":"og:description","content":"Blanc is a data QA and observability platform. It will help in bringing more quality control, predictability and automation into our data assets and pipelines."},{"property":"og:image","content":"/og-mckesson-compile-blanc.png"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap"}],"style":[],"script":[],"noscript":[],"title":"McKesson Compile Blanc"}

export const appPageTransition = {"name":"fade","mode":"out-in"}

export const appLayoutTransition = {"name":"slide","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false