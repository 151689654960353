<template>
  <div class="wrapper">
    <div class="h-screen flex align-items-center justify-content-center">
      <div
        v-if="error && (error.statusCode === 401 || error.statusCode === 403)"
      >
        <h4 class="title-lg py-0 my-2">Access Denied</h4>
        <span class="text-lg">
          You do not have access to Blanc. Please request for access.
        </span>
        <br />
        <Button label="Retry" class="cta-button-yellow" @click="onGoBack()" />
      </div>
      <div v-else class="px-5">
        <h4 class="title-lg py-0 my-2">
          {{
            error.statusCode === 404 ? 'Page not found' : 'Something went wrong'
          }}
        </h4>
        <span class="text-lg"> {{ error.statusCode }} {{ error.message }} </span
        ><br />
        <Button label="Go back" class="cta-button-yellow" @click="onGoBack()" />
      </div>
      <div class="px-5">
        <img src="assets/images/webpage-not-found.png" alt="Not found" />
      </div>
    </div>
  </div>
</template>

<script setup>
  defineProps({ error: Error })

  const appStore = useAppStore()

  const onGoBack = async () => {
    // Validate the cache/cokkies credentials/token on clikc of go back
    await appStore.fetchUser().then(() => {
      navigateTo('/')
    })
  }
</script>
