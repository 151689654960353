/** Global utility class */
export class HelperMethods {
  // Method to append the redirect url
  static urlWithRedirect(url: string) {
    return url + '?back=' + encodeURIComponent(window.location.href)
  }

  static updateRouteHash(route: string) {
    const newRoute = window.location.pathname + route
    history.pushState({}, '', newRoute)
  }

  static routeWithHash(route: string) {
    const newRoute = window.location.pathname + route
    return newRoute
  }

  static paginationText(
    pageNumber: number,
    totalCount: number,
    rowCount: number = 10,
  ) {
    const startIndex = pageNumber + 1
    const endIndex = Math.min(pageNumber + rowCount, totalCount)
    const totalItems = totalCount
    return `Showing ${startIndex}-${endIndex} of ${totalItems}`
  }

  static capitalizeAllWords(str: string): string {
    return str.replace(/\b(\w)/g, (match) => match.toUpperCase())
  }

  static valueDifference(
    expectedValue: string | number,
    actualValue: string | number,
    toFixedValue: number,
  ): number {
    const difference = (
      Math.abs(Number(expectedValue) - Number(actualValue)) * 100
    ).toFixed(toFixedValue)

    return Number(difference)
  }

  static isObjectEmpty(obj: Record<string, any>): boolean {
    return Object.keys(obj).length === 0
  }
}
