<template>
  <div>
    <NuxtLayout v-if="hasUserInfo">
      <NuxtPage />
    </NuxtLayout>
    <div v-else class="auth-loader">
      <div class="auth-loader__container">
        <h3 class="title-lg">
          Please wait while the authentication is being processed
        </h3>
        <LoaderEllipsis height="50px" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import LoaderEllipsis from './components/LoaderEllipsis.vue'

  const appStore = useAppStore()

  // Computed properties
  const hasUserInfo = computed(() => {
    return !!appStore.user.username
  })

  onMounted(async () => {
    // Authuntication on load of Application
    if (HelperMethods.isObjectEmpty(appStore.user)) {
      await appStore.fetchUser()
    }
  })
</script>
<style lang="scss">
  @import 'assets/scss/_color-palette.scss';
  .layout-enter-active,
  .layout-leave-active {
    transition: all 0.4s;
  }

  .layout-enter-from,
  .layout-leave-to {
    filter: grayscale(1);
  }

  .page-enter-active,
  .page-leave-active {
    transition: all 0.4s;
  }

  .page-enter-from,
  .page-leave-to {
    opacity: 0;
    filter: blur(1rem);
  }

  .auth-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &__container {
      padding: 2.5rem;
      background-color: $white;
      border-radius: 0.5rem;
      text-align: center;
      h3 {
        color: $dark-blue;
        margin-bottom: 0;
      }
    }
  }
</style>
