import axios, { type AxiosInstance, type AxiosResponse } from 'axios'
// Global instance to maintain single istance
let _axiosInstance: AxiosInstance
export const useAxios = () => {
  if (_axiosInstance) {
    return _axiosInstance
  }
  const appStore = useAppStore()
  // Runtime config composable to refer environment variables
  const config = useRuntimeConfig()
  _axiosInstance = axios.create({
    baseURL: config.public.adminURL,
    headers: {
      common: {},
    },
    withCredentials: true,
  })
  // _axiosInstance.defaults.timeout = 50000 //Timeout can be set here

  // Add a request interceptor
  _axiosInstance.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      // eslint-disable-next-line no-console
      console.log('Making request to ' + config.url)
      appStore.updateIsLoading(true)
      return config
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error)
    },
  )

  // Add a response interceptor
  _axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      // console.log(response.data)
      appStore.updateIsLoading(false)
      return response
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      appStore.updateIsLoading(false)
      const code = parseInt(error.response && error.response.status)
      const url = window.location.href
      if (code === 403) {
        // UnAuthorized or Forbidden.
        throw createError({
          statusCode: 403,
          statusMessage: 'FORBIDDEN',
          fatal: true,
        })
      } else if (code > 400 && code < 404 && code !== 402) {
        let redirectURL = config.public.accountsURL + '/users/~redirect/?next='
        let slug = ''
        if (!process.server && url) {
          slug = url
        } else {
          const url = error.response.config.url
          slug = url.replace(config.public.baseURL, '')
        }
        redirectURL = redirectURL + encodeURIComponent(slug)
        await navigateTo(redirectURL, { external: true })
      } else {
        throw createError({
          statusCode: error.statusCode,
          statusMessage: error.statusMessage
            ? error.statusMessage
            : 'Something bad happened on the server',
          fatal: true,
        })
      }

      return Promise.reject(error)
    },
  )
  return _axiosInstance
}
