import { type AxiosResponse } from 'axios'
import BaseService from '@/services/base-service'
import type { IUser } from '~/stores/types/app'

export default class AppService extends BaseService {
  fetchUser = async (): Promise<IUser> => {
    const config = useRuntimeConfig()
    const response: AxiosResponse<IUser> = await this.http.get(
      config.public.baseURL + '/api/v1/users/me/',
    )
    return response.data
  }
}
