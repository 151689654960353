import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import type { IEntitledProduct, IUser } from './types/app'
import { HomeView } from '@/utils/enums'
import AppService from '~/services/app-service'

export const useAppStore = defineStore('appStore', () => {
  const appService = new AppService()
  const homePageView = ref(HomeView.Dashboard)
  // State
  const user: Ref<IUser> = ref({} as IUser)
  const isLoading: Ref<number> = ref(0)

  // Mutations
  const setUser = (data: IUser) => {
    user.value = data
    const hasBlancAccess = data.entitled_products.some(
      (product: IEntitledProduct) => product.slug === 'blanc_ui',
    )
    // Sentry user context
    Sentry.setUser({ email: data.email })
    Sentry.setContext('userinfo', {
      hashid: data.hashid,
    })

    // Super user can access everything, even if user doesn't have Blanc product entitled
    if (!user.value.is_superuser && !hasBlancAccess) {
      throw createError({
        statusCode: 401,
        statusMessage: 'UNAUTHORIZED_ACCESS',
        fatal: true,
      })
    }
  }

  const updateIsLoading = (value: boolean) => {
    isLoading.value = Math.max(isLoading.value + (value ? 1 : -1), 0)
  }

  const setActiveHomeView = (activeVIew: HomeView) => {
    homePageView.value = activeVIew
  }

  // Actions
  const fetchUser = async () => {
    await appService.fetchUser().then((res: IUser) => {
      setUser(res)
    })
  }

  return {
    user,
    isLoading,
    homePageView,
    setUser,
    setActiveHomeView,
    fetchUser,
    updateIsLoading,
  }
})
