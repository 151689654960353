<template>
  <div
    class="lds-ellipsis"
    :style="{ height: props.height, width: props.width }"
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    height: {
      type: String,
      default: '75px',
    },
    width: {
      type: String,
      default: '75px',
    },
  })
</script>

<style lang="scss" scoped>
  @import 'assets/scss/_color-palette.scss';
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    display: inline-block;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    // TODO: The height and width of ellipsis dot can be dynamically configurable.
    // Incase if this loader is used across then, loader width and height
    // should be calculated based on the ellipsis height and width.
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $dark-blue;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
</style>
