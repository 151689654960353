export enum HomeView {
  Dashboard = 'Dashboard',
  JobRunReport = 'JobRunReport',
}

export enum DatasetView {
  Dataset = 'Dataset',
  DatasetRuns = 'DatasetRuns',
  DatasetRunReport = 'DatasetRunReport',
}

export enum JobsView {
  Jobs = 'Jobs',
  JobRuns = 'JobRuns',
  JobRunReport = 'JobRunReport',
}

export enum DatasetDataType {
  DoubleType = 'DoubleType',
  Fractional = 'Fractional',
  Integral = 'Integral',
  String = 'String',
  StringType = 'StringType',
}
