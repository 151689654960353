import { default as datasetbD79RjlhgWMeta } from "/Users/jedesh/Work/Repository/blanc-web/pages/dataset.vue?macro=true";
import { default as index5nKnEYtOM7Meta } from "/Users/jedesh/Work/Repository/blanc-web/pages/index.vue?macro=true";
import { default as jobsoKL1OiKfUJMeta } from "/Users/jedesh/Work/Repository/blanc-web/pages/jobs.vue?macro=true";
export default [
  {
    name: datasetbD79RjlhgWMeta?.name ?? "dataset",
    path: datasetbD79RjlhgWMeta?.path ?? "/dataset",
    meta: datasetbD79RjlhgWMeta || {},
    alias: datasetbD79RjlhgWMeta?.alias || [],
    redirect: datasetbD79RjlhgWMeta?.redirect,
    component: () => import("/Users/jedesh/Work/Repository/blanc-web/pages/dataset.vue").then(m => m.default || m)
  },
  {
    name: index5nKnEYtOM7Meta?.name ?? "index",
    path: index5nKnEYtOM7Meta?.path ?? "/",
    meta: index5nKnEYtOM7Meta || {},
    alias: index5nKnEYtOM7Meta?.alias || [],
    redirect: index5nKnEYtOM7Meta?.redirect,
    component: () => import("/Users/jedesh/Work/Repository/blanc-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: jobsoKL1OiKfUJMeta?.name ?? "jobs",
    path: jobsoKL1OiKfUJMeta?.path ?? "/jobs",
    meta: jobsoKL1OiKfUJMeta || {},
    alias: jobsoKL1OiKfUJMeta?.alias || [],
    redirect: jobsoKL1OiKfUJMeta?.redirect,
    component: () => import("/Users/jedesh/Work/Repository/blanc-web/pages/jobs.vue").then(m => m.default || m)
  }
]